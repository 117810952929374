<template>
  <div>
    <v-container v-if="componentLoading">
      <v-row justify="center">
        <v-col xs="12" lg="6">
          <h3>
            Send Lenders Deal Summary
            <v-progress-circular indeterminate class="ml-9" color="primary" />
          </h3>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="!componentLoading">
      <v-row justify="center">
        <v-col xs="12" xl="6">
          <v-expansion-panels :value="0">
            <v-expansion-panel style="background-color: transparent">
              <v-expansion-panel-header>
                <h3>
                  Resend To Lenders
                  <v-icon class="ml-4">mdi-account-sync-outline</v-icon>
                </h3>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-row>
                  <v-col>
                    <div>
                      Lender Type:
                      <span
                        class="filterLink"
                        style="margin-left: 10px"
                        @click="filterLenders('Private')"
                      >
                        Private
                      </span>
                      -
                      <span class="filterLink" @click="filterLenders('MIC')">
                        MIC
                      </span>
                      -
                      <span
                        class="filterLink"
                        @click="filterLenders('Institutional')"
                      >
                        Institutional
                      </span>
                      -
                      <span class="filterLink" @click="filterLenders('Other')">
                        Other
                      </span>
                    </div>

                    <div style="margin-top: 10px">
                      Tier Filter:
                      <span
                        style="margin-left: 10px"
                        class="filterLink"
                        @click="filterRound('tier 1')"
                      >
                        Tier 1
                      </span>
                      -
                      <span class="filterLink" @click="filterRound('tier 2')">
                        Tier 2
                      </span>
                      -
                      <span class="filterLink" @click="filterRound('tier 3')">
                        Tier 3
                      </span>
                      -
                      <span class="filterLink" @click="filterRound('tier 4')">
                        Tier 4
                      </span>
                      -
                      <span
                        class="filterLink"
                        @click="filterRound('hail mary')"
                      >
                        Hail Mary!
                      </span>
                    </div>

                    <div style="margin-top: 10px">
                      <span class="filterLink" @click="resetFilters">
                        Reset Filters
                      </span>
                    </div>

                    <div class="filterExplain">
                      <p>
                        <i>
                          Please note, when you filter, it RESETS your
                          selections. If you want to filter by lender type AND a
                          round, then select lender type first then the round.
                          You only have selected what is visible on the screen.
                          You cannot go between lender type filters to add
                          lenders.
                        </i>
                      </p>
                    </div>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-data-table
                      v-model="selectedLenders"
                      :headers="lenderHeaders"
                      :items="lenders"
                      :items-per-page="-1"
                      item-key="id"
                      :loading="tableLoading"
                      show-select
                      class="elevation-1"
                    >
                      <template v-slot:[`item.lenderType`]="props">
                        <span style="text-transform: uppercase">
                          {{ props.item.lending_profile.lender_type }}
                        </span>
                      </template>

                      <template v-slot:[`item.lenderName`]="props">
                        <span style="text-transform: capitalize">
                          {{ props.item.name }}
                        </span>
                      </template>
                    </v-data-table>

                    <v-btn
                      class="mt-5"
                      :disabled="isSubmitting"
                      @click="submitResendToLenders"
                    >
                      Resend to Lenders
                      <v-progress-circular
                        v-if="isSubmitting"
                        indeterminate
                        class="ml-5"
                        color="primary"
                      />
                    </v-btn>

                    <v-btn
                      class="mt-5 ml-4"
                      :disabled="isSubmitting"
                      @click="$router.go(-1)"
                    >
                      Cancel
                    </v-btn>

                    <v-card v-if="error" class="danger my-6">
                      <v-card-subtitle class="red--text">
                        There was an error.
                      </v-card-subtitle>
                      <v-card-text type="error" class="red--text">
                        Some of the lender's emails were inactive.
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import API from '@/plugins/API';

export default {
  props: ['deal_summary_id'],

  data() {
    return {
      isSubmitting: false,
      componentLoading: true,
      tableLoading: true,
      error: false,
      deal_summary_data: {},
      lender_display: [],
      full_lender_list: [],
      lenders: [],
      selectedLenders: [],
      lenderHeaders: [
        {
          text: 'Lender Name',
          align: 'start',
          sortable: true,
          value: 'lenderName',
        },

        {
          text: 'Lender Type',
          align: 'start',
          sortable: true,
          value: 'lenderType',
        },

        {
          text: 'Tier',
          align: 'start',
          sortable: true,
          value: 'lending_profile.tier',
        },
      ],
    };
  },

  methods: {
    filterLenders(criteria) {
      this.lenders = Object.assign(this.full_lender_list);

      let filteredLenders = this.lenders.filter((lender) => {
        if (lender.lending_profile.lender_type === criteria) {
          return lender;
        }
      });

      this.selectedLenders = [];

      this.lenders = filteredLenders;

      // console.log(filteredLenders)
    },

    filterRound(tier) {
      let filteredLenders = this.lenders.filter((lender) => {
        if (lender.lending_profile.tier === tier) {
          return lender;
        }
      });

      this.selectedLenders = [];

      this.lenders = filteredLenders;
    },

    resetFilters() {
      this.selectedLenders = [];
      this.lenders = Object.assign(this.full_lender_list);
    },

    async submitResendToLenders() {
      try {
        this.isSubmitting = true;

        if (this.selectedLenders != 0) {
          const res = await API().post(
            '/api/internal-admin/resend_lenders_to_deal_summary',
            {
              deal_summary_id: this.deal_summary_data.id,
              lenders: this.selectedLenders,
            }
          );

          console.log(res);

          if (res.status == 200) {
            this.isSubmitting = false;
            this.$router.push({
              path: `/dashboard/admin/deal/read/${this.deal_summary_data.deal.id}`,
            });
          }
        } else {
          alert('Unable to resend, please select atleast 1 lender.');
          this.isSubmitting = false;
        }
      } catch (e) {
        console.log(e.response);
        this.error = true;
        this.isSubmitting = false;
      }
    },
  },

  async mounted() {
    try {
      let deal_summary_data = await API().get(
        `/api/internal-admin/deal_summary_assign/${this.deal_summary_id}`
      );

      if (deal_summary_data.status == 200) {
        this.deal_summary_data = deal_summary_data.data;

        let existing_company_ids =
          deal_summary_data.data.deal_summary_access_links.map((el) => {
            return el.company.id;
          });

        let lender_response = await API().get(
          `api/internal-admin/lenders_criteria/?province=${this.deal_summary_data.province}`
        );

        if (lender_response.status == 200) {
          console.log('lender_response.data');
          console.log(lender_response.data);

          let assigned_lenders = lender_response.data.filter((el) => {
            if (existing_company_ids.includes(el.id)) {
              return el;
            }
          });

          console.log('assigned_lenders');

          console.log(assigned_lenders);

          this.lenders = assigned_lenders;

          this.full_lender_list = assigned_lenders;
        }

        // console.log(lender_response)

        this.componentLoading = false;

        this.tableLoading = false;

        // let lenders = await API().get(
        //   `/lender/list?prov=${this.dealData.province}`
        // );

        // if (lender_response.status == 200) {
        //   this.full_lender_list = lender_response.data;

        //   let already_assigned_lenders;

        //   // full_lender_list.data.forEach(lender => {

        //   // });
        //   // let assignedLenderIds = assignedLenders.data.map((value) => value.id);

        //   // This code block was to have the already selected lenders to show up with a checkmark, which we don't do anymore

        //   // assignedLenders.data.forEach((element) => {
        //   //   this.selectedLenders.push(element);
        //   // });

        //   // end of the code block note

        //   // this.fullLenderList = this.lenders.filter(function (lender) {
        //   //   if (!assignedLenderIds.includes(lender.id) && lender.active == true) {
        //   //     return lender;
        //   //   }
        //   // });

        //   // this.lenders = this.fullLenderList;

        //   this.componentLoading = false;
        //   this.tableLoading = false;
        // }
      }
    } catch (e) {
      console.log(e);
    }
  },
};
</script>

<style scoped>
.filterExplain {
  margin-top: 15px;
  font-size: 0.85rem;
}
/* .filterLink {
} */
.filterLink:hover {
  color: yellow;
  cursor: pointer;
}
</style>
